<template>
    <div
        v-if="!!record"
        class="journal-entry-part"
    >
        <div v-if="!!record && !!record.id" class="journal-entry-part-header">
            <div class="divider text-center" :data-content="timestamp"></div>
            <div class="journal-entry-part-controls">
                <default-button
                    v-if="!!record.sent_at"
                    flavor="link"
                    color="error"
                    size="xs"
                    class="mr-2"
                    @click.prevent.stop="confirm_deletion"
                >
                    <open-icon glyph="trash" />
                </default-button>
                <default-button
                    flavor="link"
                    size="xs"
                    @click.prevent.stop="$emit('edit', record.id)"
                >
                    <open-icon v-if="editable" glyph="check" />
                    <open-icon v-else glyph="pencil-alt" />
                </default-button>
            </div>
        </div>
        <form-editor
            v-if="editable"
            ref="editor"
            id="content"
            name="content"
            :value="record.content"
            size="lg"
            :data-version="record.id"
            :editable="true"
            :live="false"
            :required="false"
            :show-on-ready="true"
            :saving="saving('content')"
            :error="has_error('content')"
            @input="save"
        />
        <div
            v-else
            v-html="record.content"
        ></div>
        <div
            v-if="!!record.id && !record.sent_at"
            class="columns mt-4"
        >
            <div v-if="!addressing" class="column">
                <default-button
                    color="primary"
                    :block="true"
                    size="sm"
                    @click.prevent.stop="address"
                >
                    {{ translate("Send...") }}
                    <open-icon glyph="paper-plane" />
                </default-button>
            </div>
            <div v-else class="column col-auto">
                <label class="form-label">
                    {{ translate("Send to") }}:
                </label>
            </div>
            <form-tag-input
                v-if="addressing"
                id="recipients"
                name="recipients"
                v-model="recipients"
                :data-source="all_recipients"
                :show-all="true"
                :required="true"
                :disabled="sending"
                class="column"
            />
            <div v-if="addressing" class="column col-auto">
                <default-button
                    :disabled="sending"
                    class="mr-1"
                    @click.prevent.stop="addressing = !addressing"
                >
                    <open-icon glyph="times" />
                </default-button>
                <default-button
                    color="primary"
                    :waiting="sending"
                    :disabled="sending"
                    class="ml-1"
                    @click.prevent.stop="send"
                >
                    <open-icon glyph="paper-plane" />
                </default-button>
            </div>
        </div>
        <div
            v-else-if="!!record.id && !!record.sent_at"
            class="journal-entry-part-meta text-small"
        >
            {{ translate("Sent on") }} {{ record.sent_at | nibnut.date }} @ {{ record.sent_at | nibnut.date("hh:mm A") }}, {{ translate("by") }} {{ record.sent_by }}, {{ translate("to") }}
            <span
                v-for="recipient in record.sent_to"
                :key="recipient.email"
            >
                {{ recipient.name }}
            </span>
        </div>
        <confirmation
            v-if="confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { profile_utilities, handles_saving, confirms } from "@/nibnut/mixins"

import {
    FormEditor,
    FormTagInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "JournalEntryPart",
    mixins: [profile_utilities, handles_saving, confirms],
    components: {
        FormEditor,
        FormTagInput,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.refocus()
    },
    watch: {
        editable: "refocus"
    },
    methods: {
        refocus () {
            if(this.editable) {
                setTimeout(() => {
                    if(this.$refs.editor && this.$refs.editor.$el) {
                        const node = this.$refs.editor.$el.querySelector(".tiptap.ProseMirror")
                        if(node) {
                            node.focus()
                            document.getSelection().selectAllChildren(node)
                        }
                    }
                }, 200)
            }
        },
        save_field_for_record_id (entity, record_id, value, field) {
            return this.save_data_for_record_id(
                entity,
                record_id,
                {
                    [field]: this.standardize_field_save_value(value, field),
                    relation_ids: this.relation_ids
                }
            )
        },
        save (value, field) {
            if(!this.record) return Promise.resolve(null)
            if(!this.record.id) {
                this.$emit("create", value)
                return Promise.resolve(this.record)
            }
            return this.save_field_for_record_id(this.entity, this.record.id, value, field)
        },
        address () {
            this.recipients = []
            this.addressing = true
        },
        send () {
            if(!this.recipients.length) this.addressing = false
            else {
                this.sending = true
                const to = this.recipients.map(id => {
                    const matches = id.split("::")
                    return { recipient_type: matches[0], recipient_id: parseInt(matches[1]) }
                })

                this.$store.dispatch("RECORD_ACTION", {
                    entity: this.entity,
                    id: this.record.id,
                    action: "send",
                    data: {
                        to,
                        relation_ids: [...this.relation_ids, this.entity]
                    },
                    method: "put"
                }).then(record => {
                    this.addressing = false
                }).catch(this.receive_error).then(() => {
                    this.sending = false
                })
            }
        },
        confirm_deletion () {
            if(!this.record || !this.record.id || !this.record.sent_at) return
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Note Section \"{timestamp}\"", { timestamp: this.timestamp }),
                    message: this.translate("Do you really want to delete this section? There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete"),
                    delete_data: {
                        entity: this.entity,
                        id: this.record.id,
                        relation_ids: this.relation_ids
                    }
                },
                "delete-entry"
            )
        },
        confirmed () {
            if((this.confirming === "delete-entry") && this.confirmation_props.delete_data) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    this.confirmation_props.delete_data
                ).then(() => {
                    this.done_confirming()
                }).catch(this.receive_error)
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        timestamp () {
            if(!this.record) return ""
            return `${this.nibnut_filter("nibnut.date", [this.record.created_at])} @ ${this.nibnut_filter("nibnut.date", [this.record.created_at, "hh:mm A"])}`
        },
        all_recipients () {
            let all_recipients = this.entity_records("user").map(user => {
                return {
                    id: ["App\\User", user.id].join("::"),
                    name: user.name
                }
            })
            all_recipients = all_recipients.concat(this.entity_records("team").map(team => {
                return {
                    id: ["App\\Team", team.id].join("::"),
                    name: team.name
                }
            }))
            return orderBy(all_recipients, "name", "asc")
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "action_log_part",
            relation_ids: ["action_log"],
            recipients: [],
            addressing: false,
            sending: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.journal-entry-part {
    .journal-entry-part-header {
        position: relative;
        .journal-entry-part-controls {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }
    .journal-entry-part-meta {
        color: $brand-color;
        background: lighten($gray-color, 20%);
        padding: $layout-spacing-sm;
        margin-top: $layout-spacing-sm;

        span + span::before {
            content: ", "
        }
    }
    & + .journal-entry-part {
        margin-top: $unit-2;
    }
}
</style>
