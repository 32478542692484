<template>
    <form-tag-input
        v-bind="$props"
        v-on="listeners"
        @loaded="set_options"
    >
        <template v-slot:read_only><slot name="read_only"></slot>
        </template>
        <template v-slot:empty-value>
            <slot name="empty-value">
                <term-chip
                    v-for="term_id in value"
                    :key="term_id"
                    :term="term_by_id(term_id)"
                    class="chip-sm"
                />
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <template v-slot:selection>
            <slot name="selection">
                <term-chip
                    v-for="term_id in value"
                    :key="term_id"
                    :term="term_by_id(term_id)"
                    :class="{ [`chip-${size}`]: true }"
                >
                    <slot name="selection-label" :option="term_by_id(term_id)">{{ term_by_id(term_id)[labelField] }}</slot>
                    <a
                        href
                        class="btn btn-clear"
                        aria-label="Close"
                        role="button"
                        @click.prevent="unpick(term_id)"
                    ></a>
                </term-chip>
            </slot>
        </template>
        <template v-slot:selection-label="{ option }"><slot name="selection-label" :option="option"></slot></template>
    </form-tag-input>
</template>

<script>
import FormTagInput from "@/nibnut/components/Inputs/FormTagInput"
import TermChip from "@/custom/components/TermChip"

export default {
    name: "FormTermInput",
    components: {
        FormTagInput,
        TermChip
    },
    methods: {
        set_options (options) {
            this.options = options || []
            this.$emit("loaded", options)
        },
        term_by_id (id) {
            return this.options.find(option => option[this.idField] === id) || {}
        },
        unpick (id) {
            const selection = this.value.slice()
            const index = selection.indexOf(id)
            if(index >= 0) selection.splice(index, 1)
            this.$emit("input", selection, this.name, this.term_by_id(id))
        }
    },
    computed: {
        listeners () {
            const { loaded, ...listeners } = this.$listeners
            return listeners
        }
    },
    props: {
        ...FormTagInput.props
    },
    data () {
        return {
            options: []
        }
    }
}
</script>
