<template>
    <base-tag-input
        ref="field"
        v-bind="base_tag_props"
        :value="selected_options"
        :options="filtered_options"
        :expand-on-focus="expandOnFocus || showAll"
        @focus="focused"
        @search="search"
        @input="pick"
        @unpick="unpick"
    >
        <template v-slot:selection><slot name="selection"></slot></template>
        <template v-slot:selection-label="{ option }"><slot name="selection-label" :option="option"></slot></template>
    </base-tag-input>
</template>

<script>
// import debounce from "lodash/debounce"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import handles_choices from "@/nibnut/mixins/HandlesChoices"

import BaseTagInput from "./BaseTagInput"

export default {
    name: "DefaultTagInput",
    mixins: [is_nibnut_component, handles_choices],
    components: {
        BaseTagInput
    },
    methods: {
        focused () {
            if(this.showAll) this.search()
        },
        pick (value, field, option) {
            if(this.adHoc && (!value || (value === this.emptyValue))) {
                if(option[this.labelField]) this.$emit("create", option[this.labelField], field)
            } else if(value !== this.emptyValue) {
                const selection = this.value.slice()
                if(selection.indexOf(value) < 0) selection.push(value)
                this.$emit("input", selection, field, option)
            }
            this.option_query = ""
        },
        unpick (value, field, option) {
            const selection = this.value.slice()
            const index = selection.indexOf(value)
            if(index >= 0) selection.splice(index, 1)
            this.$emit("input", selection, field, option)
        }
    },
    computed: {
        base_tag_props () {
            const { value, options, dataSourceAdditionalData, expandOnFocus, ...base_tag_props } = this.$props
            return base_tag_props
        },
        selected_options () {
            const options = this.options.slice()
            return this.value.map(id => {
                return options.find(option => option[this.idField] === id) || { [this.idField]: id, [this.labelField]: id }
            })
        },
        available_options () {
            return this.options.slice().filter(option => {
                return this.value.indexOf(option[this.idField]) < 0
            })
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        glyph: {
            type: String,
            default: "ellipsis-v"
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        expandOnFocus: {
            type: Boolean,
            default: true
        },
        maxPicks: {
            type: Number,
            default: 0
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    }
}
</script>
