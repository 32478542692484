const picks_users = {
    methods: {
        load_user_as_options () {
            this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "user",
                    query: {
                        sort_by: ["first_name", "last_name"],
                        sort_dir: ["asc", "asc"],
                        fields: ["fieldset::picker"]
                    }
                }
            ).then(response => {
                this.user_as_options = this.entity_records("user", response.record_ids)
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    data () {
        return {
            user_as_options: []
        }
    }
}

export default picks_users
