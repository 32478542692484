<template>
    <span
        :style="custom_color_style(term.hex_color)"
        class="chip"
    >
        <slot>{{ term.name }}</slot>
    </span>
</template>

<script>
import ui_utilities from "@/nibnut/mixins/UiUtilities"

export default {
    name: "TermChip",
    mixins: [ui_utilities],
    props: {
        term: {
            type: Object,
            required: true
        }
    }
}
</script>
