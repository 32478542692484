<template>
    <div>
        <default-button
            v-if="is_beta_testing(profile, 'ACTION_LOGS')"
            :id="`${topicClass}-journal-btn`"
            :color="topic.notes_count ? 'primary' : 'regular'"
            :title="translate('Journal')"
            :size="size"
            :block="block"
            :disabled="disabled"
            @click.prevent.stop="displayed = !displayed"
        >
            <open-icon glyph="th-list" />
            {{ translate("Journal") }}
        </default-button>
        <default-button
            v-else
            id="page-notes"
            :color="topic.notes_count ? 'primary' : 'regular'"
            :title="translate('Notes')"
            :size="size"
            :block="block"
            :disabled="disabled"
            @click.prevent.stop="displayed = !displayed"
        >
            <open-icon v-if="topic.notes_count" glyph="comment" />
            <open-icon v-else glyph="comment-alt" />
            {{ translate("Notes") }}
        </default-button>
        <journal-dialog
            :show.sync="displayed"
            :topic-name="topicName"
            :topic-class="topicClass"
            :topic="topic"
            :people="people"
        />
    </div>
</template>

<script>
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "JournalButton",
    mixins: [addl_profile_utilities],
    components: {
        DefaultButton,
        OpenIcon,
        JournalDialog: () => import("@/custom/components/JournalDialog")
    },
    props: {
        topicClass: {
            type: String,
            required: true
        },
        topic: {
            type: Object,
            required: true
        },
        topicName: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        people: {
            type: Array,
            default () {
                return []
            }
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            displayed: false
        }
    }
}
</script>
