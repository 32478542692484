<template>
    <verte
        v-bind="$props"
        @input="save"
    >
        <slot></slot>
    </verte>
</template>

<script>
import Verte from "verte"
import "verte/dist/verte.css"

export default {
    name: "ColorPicker",
    components: {
        Verte
    },
    methods: {
        save (color) {
            if(this.ready && (color !== this.value)) this.$emit("input", color) // ignore initial input event
            this.ready = true
        }
    },
    props: {
        ...Verte.props
    },
    data () {
        return {
            ready: false
        }
    }
}
</script>
